@import '../../styles/variables';

.Match {
  display: flex;
  justify-content: space-around;
  position: relative;

  .vs-match-info {
    background-color: $black;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    text-align: center;
    transform: translateY(-50%);
    z-index: 1;
  }

  .vs-label {
    box-sizing: border-box;
    color: $white;
    font-size: calc(2rem + 1.5vw);
    font-weight: 400;
    line-height: initial;
    padding: 1rem;
  }

  .tournlet-stage {
    font-size: calc(1rem + 1vw);
    text-align: center;
    text-transform: uppercase;
  }

  .Option {
    height: auto;
  }
}
