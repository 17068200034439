@import '../../styles/variables';

.AdminPage {
  form {
    display: inline-flex;
    flex-direction: column;
    font-size: 1.6rem;
  
    label {
      display: flex;
      flex-direction: column;
    }
  
    input {
      padding: 1rem .5rem;
    }
  
    .options-container {
      margin-top: 2rem;
  
      .option-item + .option-item {
        margin-top: 1rem;
      }
    }
  }

  .link {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    &:hover {
      text-decoration: underline;
    }
  }

  .base-bg {
    background-color: $primary-base;
    
    &:hover {
      color:inherit;
    }
  }

  .message {
    font-weight: bold;
    margin-top: 2rem;
  }
}
