@import '../../styles/variables';

.Auth {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  padding-top: 10%;

  h1 {
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 30rem;
    max-width: 90%;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 2rem auto;
  }

  label + input {
    margin-top: .5rem;
  }

  input + label {
    margin-top: 2rem;
  }

  button {
    margin-top: 2rem;
  }

  .or-divider {
    align-items: center;
    display: flex;
    margin-top: 2rem;

    hr {
      background-color: $black;
      flex-grow: 1;
      height: .1rem;
      margin: auto .5rem;
      width: 5rem;
    }
  }

  .terms {
    line-height: 1.4;
    text-align: center;
  }
}
