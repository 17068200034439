$tournlet-image-width: 60vh;
$social-bar-bg-color: #5c5c5c;
$social-bar-width: calc(5rem + .5rem); //width + padding
$social-bar-btn-color: #dddddd;

:root {
  --social-bar-btn-size: 4rem;
  --social-bar-vertical-padding: 2rem;
  --social-bar-btn-margin-top: 1.5rem;
}

// colors
$green-pastel: #d0edd3;
$green-base: #42b650;
$green-dark: #286d30;

$purple-pastel: #ddd5ea;
$purple-base: #7958ab;
$purple-dark: #5b4281;

$orange-pastel: #fcddcb;
$orange-base: #f57930;
$orange-dark: #c34e0a;

$alarm-pink-pastel: #fccadc;
$alarm-pink-base: #f5307f;
$alarm-pink-dark: #b82460;

$white: #fff;
$black: #000;

$bg-color: #eee;

$content-color: #4a4a4a;

$primary-pastel: $green-pastel; 
$primary-base: $green-base; 
$primary-dark: $green-dark;

$alternative-pastel: $purple-pastel; 
$alternative-base: $purple-base; 
$alternative-dark: $purple-dark;

$accent-pastel: $orange-pastel; 
$accent-base: $orange-base; 
$accent-dark: $orange-dark;
