@import '../../../styles/variables';

.TournletCloser {
  $media-size: 28vh;

  height: 100%;
  padding-left: 1rem;

  .UserBadge {
    margin-top: 1rem;
  }

  .left {
    display: flex;
    flex-basis: 45%;
    flex-direction: column;
    flex-shrink: 0;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .right {
    height: 100%;
    overflow-y: auto;
  }

  .media {
    object-fit: cover;
    flex-shrink: 0;
    height: $media-size;
    width: $media-size;
  }

  .ranking-item {
    display: flex;
  }

  .option-ranking {
    li {
      padding-right: 1rem;

      &:last-child {
        margin-bottom: 1rem;
      }

      & + li {
        margin-top: 1rem;
      }
    }
  }

  .option-caption {
    margin-left: 2rem;
  }

  .winner {
    background-color: $green-pastel;
    bottom: 0;
    color: $accent-base;
    font-weight: bold;
    position: sticky;
    top: 0;
  }

  .suggested-tournlets {
    margin-top: auto;

    .suggested-title {
      font-weight: bold;
      margin-top: 2rem;
      margin-bottom: 0.5rem;
    }

    li {
      align-items: center;
      display: flex;
      line-height: 1.2;
    }

    li + li {
      margin-top: 0.5rem;
    }

    .play-btn {
      flex-shrink: 0;
      height: 2rem;
      margin-right: .6rem;
      padding: .2rem;
      width: 2rem;
    }
  }
}
