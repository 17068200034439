@mixin fluid-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;
  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }
  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;
    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }
  @if $fallback {
    font-size: $fallback;
  }
  font-size: $responsive;
}
