.ForceLandscape {

  @media screen and (orientation: landscape) {
    display: none;
  }

  align-items: center;
  background-color: black;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  .rotate-img {
    height: 20vh;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  p {
    color: #fff;
    font-size: 4rem;
    margin-top: 2rem;
    text-align: center;

    + p {
      font-size: 1.8rem;
      margin-top: 1rem;
    }
  }
}
