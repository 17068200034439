@import '../../../styles/variables';

.TournletCover {
  background-color: $bg-color;
  border-radius: 5rem;
  border: solid .4rem $purple-base;
  display: flex;
  overflow: hidden;

  .right {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
  }
  
  .left {
    display: flex;
    flex-basis: 40%;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    margin-right: 1rem;
    position: relative;
    text-align: center;
  }

  .UserBadge {
    margin-top: .5rem;
  }

  .stats {
    align-items: center;
    display: flex;
    margin-top: .5rem;

    .likes {
      align-items: center;
      display: flex;
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }

  .image {
    height: 100%;
    overflow: hidden;
    outline: none;
    position: relative;

    img {
      object-fit: cover;
    }
  }

  .play-btn {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    height: 5rem;
    width: 5rem;
  }
}
