@import './variables';

.Option {
  &:active .option-img {
    filter: brightness(75%);

    &:not(.hoverable) {
      box-shadow: 0 0 2rem 2rem #f6e134;
    }
  }
  
  .caption,
  .subcaption {
    color: $white;
  }
  
  .caption {
    font-weight: bold;
  }
  
  .subcaption {
    font-weight: 300;
  }
}

.option-img {
  &.hoverable:hover {
    box-shadow: 0 0 2rem 2rem #f6e134;
  }
}

