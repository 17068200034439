@import '../../styles/variables';

.Tournlet {
  background-color: $bg-color;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  .tournlet-container {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
  }

  .Match {
    flex-grow: 1;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .tournlet-title {
    flex-shrink: 0;
    font-size: calc(2.6rem + 1vw);
    line-height: initial;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
  }

  .winner {
    display: flex;
    flex-direction: column;
    z-index: 1;

    .Option,
    .play-again-btn {
      margin-left: auto;
      margin-right: auto;
      margin-top: 2rem;
    }
  }

  .TournletCover {
    margin: 0 auto;
    max-height: 100%;
    max-width: 80rem;
    width: 100%;
  }
}
