@import '../../../styles/buttons';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.SocialAuthButton {
  @extend %btn;
  align-items: center;
  color: $black;
  display: flex;
  
  .google-btn {
    &.light {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.54);
    }
  
    &.dark {
      background-color: #4285f4;
      color: #fff;
    }
  }

  .service {
    text-transform: capitalize;
  }

  img {
    height: 4rem;
    margin-right: 2.4rem;
    width: 4rem;
  }
}
