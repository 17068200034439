@import './styles/bulma';
@import './styles/reset';
@import './styles/buttons';
@import './styles/fonts';
@import './styles/basic-theme';

html, body, #root {
  height: 100%;
}

.two-col {
  display: flex;
  
  .left,
  .rigth {
    flex-basis: 50%;
    flex-grow: 1;
  }
}

.light-icon .icon-color {
  fill: $white;
}
