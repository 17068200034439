@import './variables';

$font-family: 'Ubuntu', sans-serif;
$font-size: 1.6rem;
$letter-spacing: 0;

button {
  cursor: pointer;
  font-family: $font-family;
}

%btn {
  color: $black;
  border-radius: 2rem;
  font-family: $font-family;
  font-size: $font-size;
  letter-spacing: $letter-spacing;
  min-height: 4rem;
  min-width: 4rem;
  padding: .8rem 1.5rem;
}

.big {
  @extend %btn;
  background-color: $primary-base;
  box-shadow: 0 .3rem .7rem rgba(0,0,0,.9);
  padding: 2rem;
}

.primary-btn {
  @extend %btn;
  background-color: $primary-pastel;
  color: $white;
  
  &:active {
    background-color: $primary-dark;
  }

  &:hover {
    color: $primary-pastel;
  }
}

.alt-btn {
  @extend %btn;
  background-color: $alternative-base;
  color: $white;

  &:active {
    background-color: $alternative-dark;
  }

  &:hover {
    color: $alternative-pastel;
  }
  
}

.link, .light-link {
  color: $purple-base;
  font-family: $font-family;
  font-size: $font-size;
  letter-spacing: $letter-spacing;
  padding: 1rem;
}

.light-link {
  color: $purple-pastel;
}

.play-btn {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  padding: .2rem;

  svg {
    height: 100%;
    width: 100%;
  }
}
