.Header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  nav {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
  }

  a {
    display: inline-block;  
  }
}
