@import '../../styles/variables';

.Landing {
  align-items: center;
  display: flex;
  background-color: #010101;
  // background-image: url('../../assets/room_spotlight.jpg');
  background-position: center;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;

  .landing-img {
    max-height: 60vh;
    max-width: 90vw;
    width: 40rem;
  }
}
