@import url('https://fonts.googleapis.com/css2?family=Grandstander:wght@200;400;500&family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import './mixins';
@import './variables';

$liquid-factor: 3vw;

html {
  font-size: 62.5%;
}

body {
  color: $content-color;
  margin: 0;
  font-family: 'Ubuntu';
  line-height: 1.5; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.headline-1 {
  @include fluid-font(4.2vw, 38px, 48px, 38px);
}

.headline-2 {
  @include fluid-font(3.2vw, 30.4px, 38.4px, 30.4px);
}

.subtitle-1 {
  @include fluid-font(2.56vw, 20px, 30.1px, 24px);
}

.subtitle-2 {
  @include fluid-font(2.04vw, 19.2px, 24px, 19.2px);
}

.caption {
  @include fluid-font(1.63vw, 15.4px, 19.2px, 15.4px);
}
