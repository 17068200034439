@import '../../styles/variables';

.StyleGallery {
  align-items: center;
  display: flex;
  background-color: #010101;
  background-image: url('../../assets/room_spotlight.jpg');
  background-position: center;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.style-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.style-panel {
  // background-color: dimgrey;
  width: 15vw;
  height: 15vw;
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
}

.fat-border {
  border: $purple-base solid 5px;
}

.spacer {
  width: 2vw;
  height: 2vw;
}