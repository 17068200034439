@import '../../styles/variables';

.SocialBar { 
  align-items: center;
  background-color: $social-bar-bg-color;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: var(--social-bar-vertical-padding) .5rem;
  position: absolute;
  right: 0;
  top: 0;
  width: $social-bar-width;
  
  .bottom {
    margin-top: auto;
  }

  .pixki-anchor {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    white-space: nowrap;

    img {
      height: 8rem;
    }
  }

  button + button {
    margin-top: 2rem
  }
}
