@import '../../../styles/variables';

.UserBadge {
  align-items: center;
  border-radius: 1rem;
  border: solid .2rem $content-color;
  display: flex;
  margin-right: auto;
  padding: .3rem 1rem;

  img {
    border-radius: 50%;
    height: 4rem;
    object-fit: cover;
    width: 4rem;
  }

  .created-by {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
  }
}
