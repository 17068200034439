@import '../../../styles/variables';

.Option {
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  display: flex;
  flex-direction: column;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 50%;

  .captions {
    background-color: rgba(0,0,0,.6);
    bottom: 0;
    left: 0;
    padding: 1rem;
    position: absolute;
    right: 0;
  }

  .caption {
    font-size: calc(1.4rem + 1vw);
  }

  .subcaption {
    font-size: calc(1rem + 1vw);
    margin-top: 0.5rem;
  }

  .caption,
  .subcaption {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .media {
    overflow: hidden;
    width: 100%;

    img, video {
      object-fit: cover;
    }

    p {
      align-items: center;
      display: flex;
      font-size: calc(1rem + 1vw);
      justify-content: center;
      padding: calc(2rem + 1.5vw);
    }
  }
}
